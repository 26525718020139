.wrapper {
  &__side-nav-mobile {
    position: fixed;
    width: 310px;
    height: 100%;
    background-color: color('black');
    z-index: 9999;
    padding: 1.5rem;
    overflow: auto;
    transition: .5s;
    left: -100%;
    top: 0;
    div{
      div{
        .menu{
          hr{
            border-top: 1px solid transparentize(color('gray-1'), .9);
          }
        }
      }
    }

    &.active{
      left: 0;

      ~ .bg__wrap-menu {
        left: 0;
      }
    }

    ~ .bg__wrap-menu {
      position: fixed;
      z-index: 9998;
      width: 100%;
      height: 100%;
      left: -100%;
      top: 0;
      background: rgba(0, 0, 0, 0.32);
      backdrop-filter: blur(4px);
      transition: .5s;
    }
  }
  &__navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 999 !important;
    &-menu {
      .menu__center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;

        @media screen and(max-width: $br-md) {
          position: initial;
          left: initial;
          top: initial;
          transform: initial;
          width: 100%;
        }
      }
      ul {
        li {
          margin: 0 15px;

          @media screen and(max-width: 1024px) {
            margin: 0 10px;
          }
          @media screen and(max-width: $br-md) {
            margin: 0 0;
          }
          a {
            position: relative;
            &[aria-current="page"] {
              opacity: 1;
              color: color("yellow") !important;
              &::after {
                opacity: 1;
              }
            }

            &:hover {
              color: color("yellow") !important;

              &::after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.nav__button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 50%;
  outline-style: none;
  padding: 11px 9px;

  &.light {
    background-color: #ffffff59;
    span {
      background-color: white !important;
    }
  }

  span {
    width: 100%;
    height: 2px;
    background-color: color("black");
    margin-bottom: 5px;
    display: inline-block;
    transition: 0.5s;

    &:nth-child(1) {
    }
    &:nth-child(2) {
      width: 60%;
    }
    &:nth-child(3) {
      width: 80%;
      margin-bottom: 0;
    }
  }

  &:active {
    outline-style: none;
  }
  &:hover {
    outline-style: none;
  }
  &:focus {
    outline-style: none;
  }

  &[aria-expanded="true"] {
    span {
      &:nth-child(1) {
        width: 16px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        margin-bottom: 0;
      }
      &:nth-child(2) {
        width: 16px;
        display: none;
        margin-bottom: 0;
      }
      &:nth-child(3) {
        width: 16px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
