@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/keyframes";

.section {
}

.field {
  &__input {
    border: 1px solid #e9ebed;
    background-color: #f9f9f9;
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 32px;

    &.min-250 {
      min-width: 250px;
    }

    &.w-sm-100 {
      @media screen and(max-width: $br-sm) {
        width: 100%;
        min-width: auto;
      }
    }

    &.search {
      width: 327px;
    }

    &.round__16 {
      border-radius: 16px;
    }

    &.verification {
      background-color: transparent;
      border: none;
      input {
        width: 55px;
        height: 55px;
        border-radius: 12px;
        box-shadow: none;
        background: #fefefe;
        border: 1px solid #e9ebed;
        border-radius: 24px;

        @media screen and(max-width: $br-mm) {
          width: 50px;
          height: 55px;
        }

        &:valid {
          border: 1px solid #e9ebed;
          border-radius: 24px;
          box-shadow: none;
        }

        &:hover {
          border: 1px solid color("blue");
          border-radius: 24px;
          box-shadow: none;
        }
        &:focus {
          border: 1px solid color("blue");
          border-radius: 24px;
          box-shadow: none;
        }
        &:active {
          border: 1px solid color("blue");
          border-radius: 24px;
          box-shadow: none;
        }
      }
    }

    input {
      background-color: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      &[type="date"]::-webkit-inner-spin-button,
      &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      &:hover {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }

    &.textarea {
      height: auto;
    }

    textarea {
      background-color: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      &:hover {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }
  }
}

.wrapper {
  &__left-line{
    padding-left: 3rem;
    position: relative;
    
    @media screen and(max-width: $br-sm) {
      padding-left: 2rem;
    }

    &::after{
      position: absolute;
      content: "";
      width: 4px;
      height: 100%;
      background-color: color('blue');
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__arrow {
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      transform: 0.5s;

      &:hover {
        path {
          fill: #064bb5;
        }
      }
    }

    .left {
      left: 0;
    }
    .right {
      right: 0;
    }
  }
  &__slider-pricing {
    margin: 0 -20px;
    .items {
      padding: 0 20px;
    }

    @media screen and(max-width: $br-xx) {
      margin: 0 -10px;
      .items {
        padding: 0 10px;
      }
    }
  }
  &__wrap-mm-100 {
    @media screen and(max-width: $br-mm) {
      width: 100%;
    }
  }
  &__modal-connect {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;

    .modal-content {
      padding: 2rem 1rem;

      .close__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #f9f9f9;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: -20px;
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;
      transition: 0.5s;
    }

    .content__modal-listing {
      .img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 16px;
        @media screen and(max-width: $br-xx) {
          width: 100px;
          height: 100px;
        }
      }

      .wrap {
        background: #f9f9f9;
        border-radius: 12px;
        padding: 8px 12px;
      }
    }

    .modal-dialog {
      max-width: 350px !important;
    }
  }
  &__input-code {
    @media screen and(max-width: $br-mm) {
      margin: 0 -10px;

      .field__input {
        padding: 0 10px;
      }
    }
  }
  &__form-auth {
    .content {
      padding: 2rem 4rem;
    }

    .content__center {
      height: 100vh;
    }
  }
  &__checkbox {
    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -2;

      &:checked {
        ~ .circle {
          background-color: color("blue");
          .check {
            opacity: 1;
          }
        }
      }
    }

    .circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: white;
      position: relative;
      border: 1px solid #e5e5e7;

      .check {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.5s;
        opacity: 0;
      }
    }
  }
  &__input-field {
    background: white;
    border: 1px solid #e9ebed;
    outline-style: none;
    box-shadow: none;
    border-radius: 24px;
    height: 52px;

    ~ .icon-hide {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5%;
    }

    &:hover {
      background: white;
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
    &:focus {
      background: white;
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
    &:active {
      background: white;
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
  }
  &__line-auth {
    .line {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #e9ebed;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    h5 {
      padding: 5px 15px;
      background-color: white;
    }
  }
  &__select-profile {
    padding: 2rem 1.5rem;
    border: 1px solid #e9ebed;
    border-radius: 24px;
  }
  &__wrap-sign {
    padding: 4rem;
  }
  &__accordion {
    &.active {
      .head {
        .plus {
          transform: rotate(-180deg);
        }
      }

      .desc {
        padding: 0 0;
        max-height: 80vh;
        transition: 0.5s;
        visibility: visible;
        opacity: 1;
        overflow: visible;
      }
    }
    .head {
      padding: 1rem 0;

      .plus {
        transition: 0.5s;
      }
    }
    .desc {
      padding: 0;
      max-height: 0;
      transition: 0.5s;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
    }
  }
  &__blog-wrap {
    .cover {
      width: 100%;
      height: 250px;
      border-radius: 16px;
      object-fit: cover;
    }
  }
  &__card-last-blog {
    img {
      width: 180px;
      height: 180px;
      object-fit: cover;
      border-radius: 16px;

      &.w-mm-100 {
        @media screen and(max-width: 575px) {
          width: 100%;
        }
      }
    }
  }
  &__card-included {
    padding: 1.5rem;
    border-radius: 20px;
    border: 1px solid #e9ebed;

    .list {
      padding-left: 1rem;
    }
  }
  &__list-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 15px;
    }
  }
  &__logo-wrap {
    img {
      margin: 0 20px;

      @media screen and(max-width: 1024px) {
        margin: 0 30px;
      }
    }
  }
  &__profile-user {
    .img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &__hover-card {
    transition: 0.5s;

    &:hover {
      background: linear-gradient(
        180deg,
        rgba(188, 255, 94, 0) 0%,
        rgba(188, 255, 94, 0.12) 100%
      );
      a {
        background-color: color("green");
        box-shadow: 0 15px 30px transparentize(color("green"), 0.5) !important;
      }
    }
  }
  &__list-pricing {
    padding-left: 1.3rem;

    &.list {
      @media screen and(max-width: $br-md) {
        li {
          margin-bottom: 15px;
        }
      }
    }

    &.nolist {
      list-style: none;
    }

    li {
      margin-bottom: 25px;

      @media screen and(max-width: $br-md) {
        margin-bottom: 29px;
      }
      @media screen and(max-width: $br-xx) {
        margin-bottom: 34px;
      }
      @media screen and(max-width: $br-xxs) {
        margin-bottom: 45px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__point {
    display: flex;
    align-items: center;
    &.wrap {
      border: 1px solid #e9ebed;
      border-radius: 20px;
      padding: 12px 20px;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: color("blue");
    }
  }
  &__list-archivment {
    margin: 0 -15px;
    .item {
      padding: 0 15px;

      @media screen and(max-width: $br-md) {
        flex: 0 0 40%;
        max-width: 40%;
      }
      @media screen and(max-width: $br-sm) {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
      }
    }
  }
}
.text {
  &__decoration {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
    &:focus {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
    }
  }
}

.images {
  &__detail{
    width: 100%;
    height: 400px;
    border-radius: 16px;
    object-fit: cover;
    
    @media screen and(max-width: $br-sm) {
      height: 300px;
    }
  }
  &__1 {
    position: absolute;
    bottom: -20rem;
    right: 0;
    width: 42rem;
  }
  &__2 {
    position: absolute;
    top: 15rem;
    right: 0;
    width: 42rem;
  }
  &__wrap {
    width: 100%;
    height: 330px;
    border-radius: 16px;
    object-fit: cover;
  }
}
.btn {
  &__pricing {
    @media screen and(max-width: $br-md) {
      padding: 12px 16px;
    }
  }
  &__auth {
    padding: 17px 45px;
    background-color: #e5e5e7;
    color: #a2a1a7;
    border-radius: 50px;
    display: inline-block;
    transition: 0.5s;
    box-shadow: none;

    &:hover {
      color: white;
      background-color: color("blue");
      box-shadow: 0 15px 30px transparentize(color("blue"), 0.5);
    }
  }
  &__auth-account {
    padding: 16px 10px;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #e9ebed;
    display: inline-block;
  }
  &__touch {
    padding: 17px 25px;
  }
}

.path {
}

.list {
}

.pt {
  &__100 {
    padding-top: 100px;
    @media screen and(max-width: $br-md) {
      padding-top: 61px;
    }
  }
}

.p {
  &__4 {
    padding: 4rem;
  }
}

.px {
  &-xx-10 {
    @media screen and(max-width: $br-xx) {
      padding: 0 10px;
    }
  }
}

.mt {
  &__min {
    &-17 {
      margin-top: -17rem;

      @media screen and(max-width: $br-xs) {
        margin-top: -14rem;
      }
      @media screen and(max-width: $br-xxs) {
        margin-top: -13rem;
      }
    }
  }
  &__4 {
    margin-top: 4rem;
    &.sm-0 {
      @media screen and(max-width: $br-sm) {
        margin-top: 0rem;
      }
    }
  }
  &__100 {
    margin-top: 100px;
  }
  &__5 {
    margin-top: 5rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;

    &.lg-0 {
      @media screen and(max-width: $br-lg) {
        margin-top: 0rem;
      }
    }
  }
  &__18 {
    margin-top: 18rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
}

.hr {
  &__footer {
    border-top: 1px solid #322f37;
  }
  &__line-contact {
    border-top: 1px solid #d9d9d9;
  }
}

.mb {
  &__min {
    &-18 {
      margin-bottom: -18rem;
    }
  }
  &__4 {
    margin-bottom: 4rem;
  }
  &__5 {
    margin-bottom: 5rem;
  }

  &__md-2 {
    @media screen and(max-width: $br-md) {
      margin-bottom: 1.5rem;
    }
  }
}

// typography

.text {
  &__163 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 90px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 65px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 50px !important;
      }
    }
  }

  &__100 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }
  &__150 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 120px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 100px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }

  &__200 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 150px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 125px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-mm) {
        font-size: 100px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 85px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 75px !important;
      }
    }
  }

  &__77 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }
  }

  &__80 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 55px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 65px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }
  }

  &__70 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 60px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 35px !important;
      }
    }
  }

  &__65 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 45px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 28px !important;
      }
    }
  }

  &__60 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 30px !important;
      }
    }
  }

  &__50 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 25px !important;
      }
    }
  }

  &__25 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__45 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 24px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 16px !important;
      }
    }
  }

  &__40 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 28px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__30 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 20px !important;
      }
    }
  }

  &__32 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }
  }

  &__28 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__35 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__38 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }
  }

  &__90 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 55px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xs) {
        font-size: 38px !important;
      }
    }
  }

  &__33 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 18px !important;
      }
    }
  }

  &__18 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 12px !important;
      }
    }
  }

  &__16 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 14px !important;
      }

      &-12 {
        @media screen and(max-width: 1024px) {
          font-size: 12px !important;
        }
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 10px !important;
      }

      &-12 {
        @media screen and(max-width: $br-mm) {
          font-size: 12px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }
    }
  }

  &__20 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 14px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }
  }

  &__120 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 90px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 69px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 75px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 55px !important;
      }
    }
  }

  &__26 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__22 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__12 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 10px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-xs) {
          font-size: 10px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }
  }

  &__14 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 12px !important;
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 9px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-xx) {
          font-size: 8px !important;
        }
      }
    }
  }

  &__27 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }

  &__24 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }
  }

  &__25 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__58 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 45px !important;
      }
    }
  }

  &__48 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 35px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 24px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 18px !important;
      }
    }
  }
}

.wrap {
  &__flex {
    &-1024 {
      &-100 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      &-50 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-90 {
        &-3 {
          @media screen and(max-width: 1024px) {
            flex-flow: wrap;
          }
          > .grid {
            @media screen and(max-width: 1024px) {
              flex: 0 0 calc(90% / 3);
              max-width: calc(90% / 3);
            }
          }
        }
      }

      &-auto {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-md {
      &-100 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-auto {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-sm {
      &-100 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-xs {
      &-100 {
        @media screen and(max-width: $br-xs) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-xs) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-mm {
      &-100 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-4 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
          }
        }
      }
    }
  }
}

// animated
@keyframes move1 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, 14.5rem);
  }
  75% {
    transform: translate(27rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move12 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(9.8rem, 13rem);
  }
  75% {
    transform: translate(19.7rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-27rem, 0rem);
  }
  75% {
    transform: translate(-13.4rem, 14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move22 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-19.5rem, 0rem);
  }
  75% {
    transform: translate(-10rem, 13rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, -14.5rem);
  }
  75% {
    transform: translate(-13.5rem, -14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move32 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(10rem, -13rem);
  }
  75% {
    transform: translate(-10rem, -13rem);
  }
  100% {
    transform: translate(0);
  }
}
