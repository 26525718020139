$colors: (
  "white": #ffffff,
  "black": #09080D,
  "green": #BCFF5E,
  "blue": #064BB5,
  "red": #EB5757,
  "transparent": transparent,
  "gray-1": #CDCCD0,
  "gray-2": #5E5D65,
);

// Default Font Size
$base-font-size: 16px;

// Responsive Breakpoint
$br-xxs: 375px;
$br-xx: 425px;
$br-xs: 480px;
$br-mm: 512px;
$br-sm: 576px;
$br-md: 768px;
$br-lg: 992px;
$br-xl: 1140px;
$br-xxl: 1200px;
